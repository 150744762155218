/* text */
.text-6b7280 {
  color: #6b7280 !important;
}

.text-9ca3af {
  color: #9ca3af !important;
}

.text-374151 {
  color: #374151 !important;
}

.text-FBD127 {
  color: #fbd127 !important;
}

.text-1DB474 {
  color: #1db474 !important;
}

.text-FBD127B2 {
  color: #fbd127b2 !important;
}

.text-284462 {
  color: #284462 !important;
}

.dark .dark\:text-6E849C,
.hover\:text-6E849C:hover {
  color: #6e849c !important;
}

.text-fbd127 {
  color: #fbd127 !important;
}

.text-3b82f6 {
  color: #3b82f6 !important;
}

.text-0066FF,
.dark .dark\:hover\:text-0066FF:hover {
  color: #0066ff !important;
}

.text-CDD5DD,
.dark .dark\:text-CDD5DD,
.dark .dark\:hover\:text-CDD5DD:hover {
  color: #cdd5dd !important;
}
.focus\:text-white:focus {
  color: white !important;
}

.text-000000d9 {
  color: #000000d9 !important;
}

.text-000000B2 {
  color: #000000b2 !important;
}

.text-000000cc,
.dark .dark\:text-000000cc {
  color: #000000cc !important;
}

.text-fff,
.dark .dark\:text-fff,
.dark .dark\:hover\:text-fff:hover,
.dark .dark\:placeholder-opacity-fff::placeholder {
  color: #fff !important;
}

.dark .dark\:text-ffffff99,
.text-ffffff99 {
  color: #ffffff99 !important;
}

.dark .dark\:text-3385FF {
  color: #3385ff !important;
}

.dark .dark\:text-00000099,
.text-00000099 {
  color: #00000099 !important;
}

.dark .dark\:text-FFFFFFCC,
.text-FFFFFFCC {
  color: #ffffffcc !important;
}

.text-1BD37F,
.dark .dark\:text-1BD37F {
  color: #1bd37f !important;
}

.dark .dark\:text-FFFFFFB2 {
  color: #ffffffb2 !important;
}

.text-000,
.dark .dark\:text-000,
.placeholder-opacity-000::placeholder {
  color: #000 !important;
}

.text-00aceecc {
  color: #00aceecc !important;
}

.text-0e77a8cc {
  color: #0e77a8cc !important;
}

.text-0088CCcc {
  color: #0088cccc !important;
}

.text-323232 {
  color: #323232 !important;
}

.text-7289DAcc {
  color: #7289dacc !important;
}

.text-00000080 {
  color: #00000080 !important;
}

.text-d1d5db {
  color: #d1d5db !important;
}

.text-D68A00 {
  color: #d68a00 !important;
}

.text-DADADA,
.dark .dark\:text-DADADA {
  color: #dadada !important;
}

.text-3F8CFF,
.dark .dark\:text-3F8CFF {
  color: #3f8cff !important;
}

.text-323232 {
  color: #323232 !important;
}

.text-0B9ED9,
.dark .dark\:text-0B9ED9 {
  color: #0b9ed9 !important;
}

.text-9797971A {
  color: #9797971a !important;
}

.text-9797971A {
  color: #9797971a !important;
}

.text-585858,
.dark .dark\:text-585858 {
  color: #585858 !important;
}

.text-FFFFFF80,
.dark .dark\:text-FFFFFF80 {
  color: #ffffff80 !important;
}

.text-ef4444 {
  color: #ef4444 !important;
}

.text-cd6116 {
  color: #cd6116 !important;
}

.text-ffc107 {
  color: #ffc107 !important;
}

.text-000000b3 {
  color: #000000b3 !important;
}

.text-7D7D7D {
  color: #7d7d7d !important;
}

.text-FF0000 {
  color: #ff0000 !important;
}

.text-orangered {
  color: orangered !important;
}

.text-0027A2 {
  color: #0027a2 !important;
}

.text-13B9AA,
.dark .dark\:text-13B9AA {
  color: #13b9aa !important;
}

.text-E9F2FF26,
.dark .dark\:text-E9F2FF26 {
  color: #e9f2ff26 !important;
}

.text-FFFFFFBF,
.dark .dark\:text-FFFFFFBF {
  color: #ffffffbf !important;
}

.text-2FAFE1,
.dark .dark\:text-2FAFE1 {
  color: #2fafe1 !important;
}

.text-2D2D2D,
.dark .dark\:text-2D2D2D {
  color: #2d2d2d !important;
}

.placeholder-color-unset::placeholder {
  color: unset !important;
}

.placeholder-opacity-100::placeholder {
  opacity: 1 !important;
}

/* bg-color */

.bg-success {
  background: radial-gradient(
    239.54% 76.88% at 28.52% 42.05%,
    #3aae42 0%,
    #0b7e4a 37.48%,
    #0e9260 100%
  ) !important;

  @media (max-width: 768px) {
    background: radial-gradient(
      184.98% 104.85% at 33.59% 18.86%,
      #3aae42 0%,
      #0b7e4a 37.38%,
      #0e9260 92.23%
    );
  }
}

.bg-failed {
  background: radial-gradient(
    169.17% 79.27% at 48.91% 39.89%,
    #f22c2c 0%,
    #b21111 51.23%,
    #c42e2e 100%
  ) !important;

  @media (max-width: 768px) {
    background: radial-gradient(
      147.1% 100.09% at 41.92% 18.49%,
      #f22c2c 0%,
      #b21111 34%,
      #c42e2e 93.67%
    ) !important;
  }
}

.bg-canceled {
  background: radial-gradient(
    169.17% 79.27% at 48.91% 39.89%,
    #f5b02f 0%,
    #bb7b04 51.23%,
    #b88629 100%
  ) !important;

  @media (max-width: 768px) {
    background: radial-gradient(
      147.1% 100.09% at 41.92% 18.49%,
      #f5b02f 0%,
      #bb7b04 34%,
      #b88629 93.67%
    ) !important;
  }
}

.dark .dark\:bg-FFFFFF14,
.bg-FFFFFF14 {
  background-color: #ffffff14 !important;
}

.dark .dark\:bg-F6FFFE0F,
.bg-F6FFFE0F {
  background-color: #f6fffe0f !important;
}

.dark .dark\:bg-FFFFFF0D,
.bg-FFFFFF0D {
  background-color: #ffffff0d !important;
}

.dark .dark\:bg-1BD37F80,
.bg-1BD37F80 {
  background-color: #1bd37f80 !important;
}

.dark .dark\:bg-1DB4741A,
.bg-1DB4741A {
  background-color: #1db4741a !important;
}

.dark .dark\:bg-00000029,
.bg-00000029 {
  background-color: #00000029 !important;
}

.dark .dark\:bg-FF103B1A,
.bg-FF103B1A {
  background-color: #ff103b1a !important;
}

.dark .dark\:bg-transparent,
.bg-transparent {
  background-color: transparent !important;
}

.dark .dark\:bg-F6FFFE,
.bg-F6FFFE {
  background-color: #f6fffe !important;
}

.dark .dark\:bg-07121e,
.bg-07121e {
  background-color: #07121e !important;
}

.dark .dark\:bg-FFFFFF0F {
  background-color: #ffffff0f !important;
}

.bg-0000001A {
  background-color: #0000001a !important;
}

.bg-ECF5E7 {
  background-color: #ecf5e7 !important;
}

.bg-FCF0E4 {
  background-color: #fcf0e4 !important;
}

.bg-F1F1F180 {
  background-color: #f1f1f180 !important;
}

.dark .dark\:bg-07121E {
  background-color: #07121e !important;
}

.bg-FBD127 {
  background-color: #fbd127 !important;
}

.bg-FFC107 {
  background-color: #ffc107 !important;
}

.bg-13B9AA1A {
  background-color: #13b9aa1a !important;
}

.bg-FFC10733 {
  background-color: #ffc10733 !important;
}

.bg-EFEFEF {
  background-color: #efefef !important;
}

.bg-323232 {
  background-color: #323232 !important;
}

.bg-13B9AA {
  background-color: #13b9aa !important;
}

.bg-F5F5F5 {
  background-color: #f5f5f5 !important;
}

.bg-F0F0F0 {
  background-color: #f0f0f0 !important;
}

.bg-ff000033 {
  background-color: #ff000033 !important;
}

.bg-1bd37f33 {
  background-color: #1bd37f33 !important;
}

.bg-13b9aa-gradient {
  background-color: linear-gradient(0deg, #13b9aa, #13b9aa),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important;
}

.bg-152b53,
.dark .dark\:bg-152b53 {
  background-color: #152b53 !important;
}

.bg-2FAFE126,
.dark .dark\:bg-2FAFE126 {
  background-color: #2fafe126 !important;
}

.bg-00000033,
.dark .dark\:bg-00000033 {
  background-color: #00000033 !important;
}

.dark .dark\:bg-CF060C,
.bg-CF060C {
  background-color: #cf060c !important;
}

.dark .dark\:bg-F7F7F714,
.bg-F7F7F714 {
  background-color: #f7f7f714 !important;
}

.dark .dark\:bg-2a1216,
.bg-2a1216 {
  background-color: #2a1216 !important;
}

.dark .dark\:bg-1BD37F33,
.bg-1BD37F33 {
  background-color: #1bd37f33 !important;
}

.dark .dark\:bg-FFFFFF26,
.bg-FFFFFF26 {
  background-color: #ffffff26 !important;
}

.dark .dark\:bg-13221c,
.bg-13221c {
  background-color: #13221c !important;
}

.dark .dark\:bg-0f203e,
.bg-0f203e {
  background-color: #0f203e !important;
}

.dark .dark\:bg-121212,
.dark.dark\:bg-121212,
.bg-121212 {
  background-color: #121212 !important;
}

.dark .dark\:bg-121212,
.bg-121212 {
  background-color: #121212 !important;
}

.dark .dark\:bg-122a4e,
.bg-122a4e {
  background-color: #122a4e !important;
}

.dark .dark\:bg-F7F7F70D,
.bg-F7F7F70D {
  background-color: #f7f7f70d !important;
}

.bg-dbeafe {
  background-color: #dbeafe !important;
}

.bg-8BB5F6 {
  background-color: #8bb5f6 !important;
}

.bg-0066FF {
  background-color: #0066ff !important;
}

.bg-00BB69 {
  background-color: #00bb69 !important;
}

.bg-DEF7EC {
  background-color: #def7ec !important;
}

.bg-0B9ED9 {
  background-color: #0b9ed9 !important;
}

.bg-0B9ED9-dark {
  background-color: rgba(11, 158, 217, 0.7) !important;
}

.bg-F7F7F7 {
  background-color: #f7f7f7 !important;
}

.bg-F5F5F50A,
.dark .dark\:bg-F5F5F50A {
  background-color: #f5f5f50a !important;
}

.bg-000,
.dark .dark\:bg-000 {
  background-color: #000 !important;
}

.bg-fff,
.dark .dark\:bg-fff {
  background-color: #fff !important;
}

.bg-FAFBFC {
  background-color: #fafbfc !important;
}

.bg-1BD37F {
  background-color: #1bd37f !important;
}

.bg-1BD37F26 {
  background-color: #1bd37f26 !important;
}

.bg-0000000F {
  background-color: #0000000f !important;
}

.bg-1BD37F {
  background-color: #1bd37f !important;
}

.bg-FF0000 {
  background-color: #ff0000 !important;
}

.bg-FF00001A {
  background-color: #ff00001a !important;
}

.bg-c13d5440 {
  background-color: #c13d5440 !important;
}

.bg-BBF2D9 {
  background-color: #bbf2d9 !important;
}

.bg-005374 {
  background-color: #005374 !important;
}

.bg-f9fafb {
  background-color: #f9fafb !important;
}

.bg-FAFAFA {
  background-color: #fafafa !important;
}

.bg-0066ff14 {
  background-color: #0066ff14 !important;
}

.bg-EDEDED {
  background-color: #ededed !important;
}

.bg-13B9AA {
  background-color: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    #13b9aa !important;
}

.bg-fef5e6 {
  background-color: #fef5e6 !important;
}

.bg-00000026 {
  background-color: #00000026 !important;
}

.bg-fef5e6 {
  background-color: #fef5e6 !important;
}

.bg-3385ff26,
.dark .dark\:bg-3385ff26 {
  background-color: #3385ff26 !important;
}

.bg-ebf8ff,
.dark .dark\:bg-ebf8ff {
  background-color: #ebf8ff !important;
}

.bg-0066ff33,
.dark .dark\:bg-0066ff33 {
  background-color: #0066ff33 !important;
}

.dark .dark\:bg-11182c,
.bg-11182c {
  background-color: #11182c !important;
}

.dark .dark\:bg-070707,
.bg-070707 {
  background-color: #070707 !important;
}

.dark .dark\:bg-1E1E1E,
.dark .dark\:hover\:bg-1E1E1E:hover,
.bg-1E1E1E {
  background-color: #1e1e1e !important;
}

.dark .dark\:bg-2D2D2D,
.bg-2D2D2D {
  background-color: #2d2d2d !important;
}

.dark .dark\:bg-262626,
.bg-262626 {
  background-color: #262626 !important;
}

.dark .dark\:bg-FFFFFF1A,
.bg-FFFFFF1A {
  background-color: #ffffff1a !important;
}

.hover\:bg-f3f4f6:hover,
.dark .dark\:hover\:bg-f3f4f6:hover,
.bg-f3f4f6 {
  background-color: #f3f4f6 !important;
}

.hover\:bg-000000ad:hover,
.dark .dark\:hover\:bg-000000ad:hover {
  background-color: #000000ad !important;
}

.hover\:bg-e5e7eb:hover,
.bg-e5e7eb {
  background-color: #e5e7eb !important;
}

.dark .dark\:hover\:bg-29355f:hover,
.bg-29355f {
  background-color: #29355f !important;
}

.dark .dark\:hover\:bg-4b5563:hover {
  background-color: #4b5563 !important;
}

.bg-0066ff26,
.dark .dark\:bg-0066ff26,
.hover\:bg-0066ff26:hover,
.focus\:bg-0066ff26:focus {
  background-color: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0.15)
    ),
    linear-gradient(0deg, #0066ff, #0066ff) !important;
}

.bg-0066ff1a,
.dark .dark\:bg-0066ff1a,
.hover\:bg-0066ff1a:hover,
.focus\:bg-0066ff1a:focus {
  background-color: #0066ff1a !important;
}

///////////////

.border-0055d399,
.dark .dark\:border-0055d399,
.hover\:border-0055d399:hover,
.focus\:border-0055d399:focus {
  border-color: #0055d399 !important;
}

.border-0000000A,
.dark .dark\:border-0000000A {
  border-color: #0000000a !important;
}

.border-2FAFE180,
.dark .dark\:border-2FAFE180 {
  border-color: #2fafe180 !important;
}

.border-3F8CFF,
.dark .dark\:border-3F8CFF {
  border-color: #3f8cff !important;
}

.border-0066FF33,
.dark .dark\:border-0066FF33,
.hover\:border-0066FF33:hover,
.focus\:border-0066FF33:focus {
  border-color: #0066ff33 !important;
}

.border-0054d399,
.dark .dark\:border-0054d399,
.hover\:border-0054d399:hover,
.focus\:border-0054d399:focus {
  border-color: #0054d399 !important;
}

.border-ffffff80,
.dark .dark\:border-ffffff80,
.hover\:border-ffffff80:hover,
.focus\:border-ffffff80:focus {
  border-color: #ffffff80 !important;
}

.border-0066ff26,
.dark .dark\:border-0066ff26,
.hover\:border-0066ff26:hover,
.focus\:border-0066ff26:focus {
  border-color: #0066ff26 !important;
}

.border-0066FF99,
.dark .dark\:border-0066FF99,
.hover\:border-0066FF99:hover {
  border-color: #0066ff99 !important;
}

.border-0066ff1a,
.dark .dark\:border-0066ff1a,
.hover\:border-0066ff1a:hover,
.focus\:border-0066ff1a:focus {
  border-color: #0066ff1a !important;
}

.border-0000000D,
.dark .dark\:border-0000000D {
  border-color: #0000000d !important;
}

.border-FFFFFF0D,
.dark .dark\:border-FFFFFF0D {
  border-color: #ffffff0d !important;
}

.border-transparent,
.dark .dark\:border-transparent {
  border-color: transparent !important;
}

/* outline */
.outline-0055d399,
.hover\:outline-0055d399:hover,
.focus\:outline-0055d399:focus {
  outline-color: #0055d399 !important;
}

.outline-0066FF99,
.hover\:outline-0066FF99:hover,
.focus\:outline-0066FF99:focus {
  outline-color: #0066ff99 !important;
}

.outline-0066FF,
.hover\:outline-0066FF:hover,
.focus\:outline-0066FF:focus {
  outline-color: #0066ff !important;
}

/* button */

.button-d {
  background-color: transparent !important;
}

.button-d-0066ff1a {
  background-color: #0066ff1a !important;
}

.button-d-0066FF {
  border-color: #0066ff !important;
  background-color: transparent !important;
}

.button-d,
.button-d-0066FF,
.button-d-0066ff1a {
  &:hover,
  &:focus {
    background-color: linear-gradient(0deg, #0066ff1a, #0066ff1a), #ffffff !important;
  }
}

.button-0066FF {
  background-color: #0066ff !important;
  border-color: #0066ff !important;

  &:hover,
  &:focus {
    background-color: linear-gradient(0deg, #00000026, #00000026), #0066ff !important;
  }

  &:focus {
    outline: 3px solid #0055d399 !important;
  }
}

.focus\:bg-0066FF:focus {
  background-color: #0066ff !important;
}

.primary-button {
  background-color: #0066ff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0057d9 !important;
  }

  &:focus {
    background-color: #0059d8 !important;
    border: 3px solid #0055d399;
  }

  &:disabled {
    background-color: #7ab6f6 !important;
    cursor: default;
  }

  .dark &:disabled {
    background-color: #385e81 !important;
    cursor: default;
  }
}

.Secondary-button {
  color: #0066ff;
  background-color: white !important;
  border: 2px solid #0066ff;
  cursor: pointer;

  &:hover {
    background-color: #e2f0ff !important;
  }

  &:focus {
    background-color: #e2f0ff !important;
    border: 3px solid #0066ff99;
  }

  &:disabled {
    color: #87b2f6;
    border: 2px solid #87b2f6;
    cursor: default;
  }

  .dark &:disabled {
    color: #435d81;
    border: 2px solid #435d81;
    cursor: default;
  }
}

.Third-button {
  color: #0066ff;
  background-color: white !important;
  cursor: pointer;

  &:hover {
    background-color: #e2f0ff !important;
  }

  &:disabled {
    color: #87b2f6;
    cursor: default;
  }
}

// button disabled
button[ng-reflect-disabled="true"] {
  opacity: 0.5;
}

// border

.dark .dark\:border-g-ffffff33-ffffff0a {
  border-image-source: linear-gradient(
    90.72deg,
    #ffffff33 0%,
    #ffffff0a 100%
  ) !important;
}

.border-0000001A {
  border-color: #0000001a !important;
}
/////////////

.border-e5e7eb {
  border-color: #e5e7eb !important;
}

.border-0066FF {
  border-color: #0066ff !important;
}

.border-00000010 {
  border-color: #00000010 !important;
}

.border-BFD8FF {
  border-color: #bfd8ff !important;
}

.border-E3EEFF {
  border-color: #e3eeff !important;
}

.dark .dark\:border-2D2D2D,
.border-2D2D2D {
  border-color: #2d2d2d !important;
}

.dark .dark\:border-BFD8FF33,
.border-BFD8FF33 {
  border-color: #bfd8ff33 !important;
}

.dark .dark\:border-1E1E1E,
.border-1E1E1E {
  border-color: #1e1e1e !important;
}

.dark .dark\:border-ffffff10,
.border-ffffff10 {
  border-color: #ffffff10 !important;
}

.border-d1d5db {
  border-color: #d1d5db !important;
}

.border-ef4444 {
  border-color: #ef4444 !important;
}

.dark .dark\:border-00000012,
.border-00000012 {
  border-color: #00000012 !important;
}

.dark .dark\:border-fff,
.border-fff {
  border-color: #fff !important;
}

.border-000 {
  border-color: #000 !important;
}

.border-3b82f6 {
  border-color: #3b82f6 !important;
}

.border-0000000f {
  border-color: #0000000f !important;
}

.dark .dark\:border-ffffff40,
.border-ffffff40 {
  border-color: #ffffff40 !important;
}

.dark .dark\:border-0d3e76,
.border-0d3e76 {
  border-color: #0d3e76 !important;
}

.dark .dark\:border-3385ff,
.dark .dark\:hover\:border-3385ff:hover,
.dark .dark\:focus\:border-3385ff:focus,
.border-3385ff {
  border-color: #3385ff !important;
  border-color: #3385ff !important;
}

.border-E7E7E7 {
  border-color: #e7e7e7 !important;
}

.border-323232 {
  border-color: #323232 !important;
}

.border-e5e7eb {
  border-color: #e5e7eb !important;
}

.border-9797971a {
  border-color: #9797971a !important;
}

.dark .dark\:border-FFFFFF1A,
.border-FFFFFF1A {
  border-color: #ffffff1a !important;
}

.dark .dark\:border-4b5563,
.border-4b5563 {
  border-color: #4b5563 !important;
}

.dark .dark\:border-595962,
.border-595962 {
  border-color: #595962 !important;
}

.dark .dark\:border-595961,
.border-595961 {
  border-color: #595961 !important;
}

.dark .dark\:border-0B9ED9,
.border-0B9ED9 {
  border-color: #0b9ed9 !important;
}

@media (max-width: 500px) {
  .xs\:bg-fff {
    background-color: #ffffff !important;
  }
}

.border-dashed-0000001a {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%230000001A' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
}

.dark .dark\:border-dashed-white {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='white' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 4px;
}

.bg-blur-4px {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

// other
.fill-transparent {
  svg {
    fill: transparent;
  }
}
