// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import url("assets/css/bootstrap.css");
@import url("assets/css/base.css");
@import url("assets/css/font.css");
@import url("assets/css/color.scss");

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;

* {
  font-feature-settings: unset !important;
  font-variant: unset !important;
  font-family: OpenSans-Regular;
  line-height: unset !important;
}

.ant-btn {
  transition: none !important;
}

nz-table {
  .ant-table-thead > tr > th {
    transition: none !important;
  }

  .ant-table-header {
    overflow-y: hidden !important;
  }
}

.ant-table {
  thead {
    > tr {
      > th {
        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          content: none;
        }
      }
    }
  }

  thead > tr > th,
  tbody > tr > td,
  tfoot > tr > th,
  tfoot > tr > td {
    // padding: 6px 16px;
  }

  .ant-table-body {
    overflow: auto !important;
  }
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.3) 25%,
    rgba(129, 129, 129, 0.34) 37%,
    rgba(190, 190, 190, 0.3) 63%
  );
  animation: ant-skeleton-loading 1.4s ease infinite;
  background-size: 400% 100%;
}

.spiner {
  -webkit-animation: spinner 0.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.ant-tooltip-arrow-content::before {
  background: black;
}

.ant-tooltip-inner {
  background: black;
  border-radius: 6px;
}

.help-pop-confirm {
  .ant-popover-inner {
    background-color: #0066ff;
    border-radius: 8px;
    width: 280px;
  }
  .ant-popover-arrow-content::before {
    background-color: #0066ff;
  }
  .ant-popover-buttons {
    display: none;
  }
}

/* ant-design custome style  */

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-btn {
  height: 46px !important;

  &.h-unset {
    height: unset !important;
  }
}

.ant-btn-lg {
  height: 59px !important;

  &.h-unset {
    height: unset !important;
  }
}

.ant-btn-sm {
  height: 31px !important;

  &.h-unset {
    height: unset !important;
  }
}

.ant-radio-button-wrapper {
  .ant-radio-button + span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.ant-checkbox-disabled + span {
  color: unset;
  opacity: 0.5;
}

::ng-deep {
  .failedClass a {
    font-weight: normal !important;
    font-family: OpenSans-SemiBold;
    font-size: 12px !important;
    line-height: inherit;
    color: #f70000;
  }

  .errorClass {
    font-weight: normal !important;
    font-family: OpenSans-SemiBold;
    font-size: 12px !important;
    line-height: inherit;
    color: #f70000;
  }

  .failedClass a:hover {
    color: #f70000;
    text-decoration: none;
  }

  .successClass a {
    font-weight: normal !important;
    font-family: OpenSans-SemiBold;
    font-size: 12px !important;
    line-height: inherit;
    color: #219b0b;
  }

  .successClass a:hover {
    color: #219b0b;
    text-decoration: none;
  }

  .ngx-toastr {
    padding: 15px 15px 15px 15px !important;
  }

  .toast-success {
    width: 256px !important;
    height: 90px !important;
    background-image: none !important;
    background-color: #ffffff !important;
    border-radius: 4px !important;
    border-left-width: 4px;
    border-color: #08b861;
  }

  .toast-title {
    font-weight: normal !important;
    font-family: OpenSans-Regular;
    font-size: 14px !important;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.9);
  }

  .toast-error {
    width: 256px !important;
    height: 90px !important;
    background-image: none !important;
    background-color: #ffffff !important;
    border-radius: 4px !important;
    border-left-width: 4px;
    border-color: #f70000;
  }

  .toast-close-button {
    font-weight: normal !important;
    color: #000000 !important;
  }

  .custom-toast-top-right {
    top: 70px;
    right: 12px;
  }

  .toast-container .ngx-toastr {
    color: unset !important;
  }
}
