.font-28 {
  font-size: 28px !important;
}

.font-100 {
  font-size: 100px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-8 {
  font-size: 8px !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.line-height-10px {
  line-height: 10px !important;
}

.line-height-initial {
  line-height: initial !important;
}

.line-height-full {
  line-height: 100% !important;
}

/* font weight */

.font-w-900 {
  font-weight: 900 !important;
}

.font-w-800 {
  font-weight: 800 !important;
}

.font-w-700 {
  font-weight: 700 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

/* alignment */

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

/* letter-spacing */
.letter-spacing-normal {
  letter-spacing: normal !important;
}

@media (max-width: 768px) {
  .md\:font-16 {
    font-size: 16px !important;
  }
}

@media (min-width: 768px) {
  .font-md-12 {
    font-size: 12px !important;
  }

  .font-md-14 {
    font-size: 14px !important;
  }

  .font-md-16 {
    font-size: 16px !important;
  }

  .font-md-20 {
    font-size: 20px !important;
  }
}

@media (min-width: 576px) {
  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-14 {
    font-size: 14px !important;
  }

  .font-sm-16 {
    font-size: 16px !important;
  }

  .font-sm-20 {
    font-size: 20px !important;
  }

  .font-sm-28 {
    font-size: 28px !important;
  }

  .font-sm-w-900 {
    font-weight: 900 !important;
  }

  .font-sm-w-800 {
    font-weight: 800 !important;
  }

  .font-sm-w-700 {
    font-weight: 700 !important;
  }

  .font-sm-w-500 {
    font-weight: 500 !important;
  }
}

@media (min-width: 400px) {
  .font-xs-12 {
    font-size: 12px !important;
  }

  .font-xs-14 {
    font-size: 14px !important;
  }

  .font-xs-16 {
    font-size: 16px !important;
  }

  .font-xs-20 {
    font-size: 20px !important;
  }

  .font-xs-28 {
    font-size: 28px !important;
  }
}
