a {
  color: inherit !important;
  text-decoration: inherit !important;
}

.no-text-wrap {
  white-space: nowrap !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-wrap-balance {
  text-wrap: balance !important;
}

.letter-spacing0\.1px {
  letter-spacing: 0.1px !important;
}

.letter-spacing0\.5px {
  letter-spacing: 0.5px !important;
}

.h-fitcontent {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.h-max-content {
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
  height: 100dvh !important;
}

.h-30px {
  height: 30px !important;
}

.h-42px {
  height: 42px !important;
}

.h-46px {
  height: 46px !important;
}

.h-48px {
  height: 48px !important;
}

.h-45px {
  height: 45px !important;
}

.h-22px {
  height: 22px;
}

.h-20px {
  height: 20px;
}

.h-18px {
  height: 18px;
}

.h-unset {
  height: unset !important;
}

.no-resize {
  resize: none;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.w-200px {
  width: 200px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.w-46px {
  width: 46px !important;
}

.w-130px {
  width: 130px !important;
}

.w-150-px {
  width: 150px !important;
}

.w-400px {
  width: 400px !important;
}

.w-500px {
  width: 500px !important;
}

.max-w-unset {
  max-width: unset !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-100px {
  max-width: 100px !important;
}

.w-max-content {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.m-w-max-content {
  max-width: -webkit-max-content !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.scale-60 {
  transform: scale(0.6) !important;
}

.top--20px {
  top: -20px !important;
}

.m-10px {
  margin: 10px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-44px {
  margin-left: 44px !important;
}

.ml-2px {
  margin: 2px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.m-20px {
  margin: 20px !important;
}

.my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mx-12px {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-24px {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-80px {
  margin-left: 80px !important;
}

.ml-8px {
  margin-left: 8px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.ml-6px {
  margin-left: 6px !important;
}

.mt-2px {
  margin-top: 2px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-12px {
  margin-top: 12px !important;
}

.mt-28px {
  margin-top: 28px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.mt-70px {
  margin-top: 70px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt--28px {
  margin-top: -28px !important;
}

.mt--20px {
  margin-top: -20px !important;
}

.mt-18px {
  margin-top: 18px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-70px {
  margin-bottom: 70px !important;
}

.m--10px {
  margin: -10px !important;
}

.m--8px {
  margin: -8px !important;
}

.ml--10px {
  margin-left: -10px !important;
}

.mr--10px {
  margin-right: -10px !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.mr-12px {
  margin-right: 12px !important;
}

.mr-13px {
  margin-right: 13px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mx-14px {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.m-14px {
  margin: 14px !important;
}

.mr-14px {
  margin-right: 14px !important;
}

.ml-14px {
  margin-left: 14px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx--10px {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.mx--8px {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.mx--4px {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.mx--14 {
  margin-left: -14px !important;
  margin-right: -14px !important;
}

.gap-10px {
  gap: 10px !important;
}

.gap-2px {
  gap: 2px !important;
}

.mr--4px {
  margin-right: -4px !important;
}

.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-14px {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.px-18px {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.px-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-7px {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pr-12px {
  padding-right: 12px !important;
}

.pl-40px {
  padding-left: 40px !important;
}

.pl-12px {
  padding-left: 12px !important;
}

.pl-24px {
  padding-left: 24px !important;
}

.p-1px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.p-22px {
  padding: 22px !important;
}

.p-30px {
  padding: 30px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-3px {
  padding: 3px !important;
}

.p-4px {
  padding: 4px !important;
}

.px-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.p-6px {
  padding: 6px !important;
}

.pr-10px,
.px-10px,
.p-10px {
  padding-right: 10px !important;
}

.pl-10px,
.px-10px,
.p-10px {
  padding-left: 10px !important;
}

.pt-10px,
.py-10px,
.p-10px {
  padding-top: 10px !important;
}

.pb-10px,
.py-10px,
.p-10px {
  padding-bottom: 10px !important;
}

.pt-12px {
  padding-top: 12px !important;
}

.pt-18px {
  padding-top: 18px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pt-32px {
  padding-top: 32px !important;
}

.py-32px {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.pb-22px {
  padding-bottom: 22px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pb-2px {
  padding-bottom: 2px !important;
}

.p-12px {
  padding: 12px !important;
}

.pb-12px {
  padding-bottom: 12px !important;
}

.p-12px {
  padding: 12px !important;
}

.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-14px {
  padding: 14px !important;
}

.p-20px {
  padding: 20px !important;
}

.pr-20px {
  padding-right: 20px !important;
}

.pl-20px {
  padding-left: 20px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.p-25px {
  padding: 25px !important;
}

.pb-40px {
  padding-bottom: 40px !important;
}

.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

body .pt-10px {
  padding-top: 10px !important;
}

.opacity-500 {
  opacity: 0.5 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-20 {
  z-index: 20 !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.absolute-y-center {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-x-center {
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.overflow-ellipsis-100 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.rotate--90 {
  transform: rotate(-90deg) !important;
}

.rotate-x-180 {
  transform: rotateX(180deg) !important;
}

.transition-transform-03 {
  transition: transform 0.3 linear;
}

.transition-03-linear {
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.position-fixed {
  position: fixed !important;
}

.shadow-none {
  box-shadow: none !important;
}

.outline-none {
  outline: none !important;
}

.line-height-24px {
  line-height: 24px !important;
}

.line-height-16px {
  line-height: 16px !important;
}

.line-height-0 {
  line-height: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-context-menu {
  cursor: context-menu !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

/* border */
.border-solid {
  border-style: solid !important;
}

.border-1px {
  border-width: 1px !important;
}

.border-bottom-1px {
  border-bottom-width: 1px !important;
}

.border-2px,
.border-2px-hover:hover,
.border-2px-focus:focus {
  border-width: 2px !important;
}

.border-3px,
.hover\:border-3px:hover,
.focus\:border-3px:focus {
  border-width: 3px !important;
}

.outline-3px,
.hover\:outline-3px:hover,
.focus\:outline-3px:focus {
  outline-width: 3px !important;
}

.outline-2px,
.hover\:outline-2px:hover,
.focus\:outline-2px:focus {
  outline-width: 2px !important;
}

.border-right-1px {
  border-right-width: 1px !important;
}

.border-left-1px {
  border-left-width: 1px !important;
}

.outline-solid,
.focus\:outline-solid:focus {
  outline-style: solid !important;
}

.border-radius-2px {
  border-radius: 2px !important;
}

.border-radius-3px {
  border-radius: 3px !important;
}

.border-radius-4px {
  border-radius: 4px !important;
}

.border-radius-5px {
  border-radius: 5px !important;
}

.border-radius-6px {
  border-radius: 6px !important;
}

.border-top-radius-6px {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.border-top-radius-30px {
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
}

.border-radius-20px {
  border-radius: 20px !important;
}

.border-radius-23px {
  border-radius: 23px !important;
}

.border-radius-30px {
  border-radius: 30px !important;
}

.border-left-radius-4px {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.border-right-radius-4px {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.border-top-radius-4px {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.border-left-radius-6px {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.border-right-radius-6px {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.border-left-radius-30px {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.border-right-radius-30px {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.border-radius-8px {
  border-radius: 8px !important;
}

.border-radius-12px {
  border-radius: 12px !important;
}

.border-radius-14px {
  border-radius: 14px !important;
}

.border-radius-15px {
  border-radius: 15px !important;
}

.border-radius-16px {
  border-radius: 16px !important;
}

.border-radius-21px {
  border-radius: 21px !important;
}

.border-radius-24px {
  border-radius: 24px !important;
}

.border-0,
.focus\:border-0:focus {
  border-width: 0 !important;
}

@media (min-width: 1024px) {
  body .d-lt-none {
    display: none !important;
  }

  body .d-lt-block {
    display: block !important;
  }

  body .px-lt-24px {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  body .mb-lt-20px {
    margin-bottom: 20px !important;
  }

  body .justify-content-lt-start {
    justify-content: start !important;
  }
}

@media (max-width: 1024px) {
  body .container {
    max-width: 100% !important;
  }
}

@media (min-width: 1200px) {
  body .d-lg-flex {
    display: flex !important;
  }
}

@media (min-width: 768px) {
  .m-md-80px {
    margin-left: 80px !important;
  }

  .mt-md-60px {
    margin-top: 60px !important;
  }

  .mb-md-40px {
    margin-bottom: 40px !important;
  }

  .mb-md-30px {
    margin-bottom: 30px !important;
  }

  .mb-md-20px {
    margin-bottom: 20px !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-max-content {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .p-md-20px {
    padding: 20px !important;
  }

  .pt-md-30px {
    padding-top: 30px !important;
  }

  .pb-md-40px {
    padding-bottom: 40px !important;
  }

  body .p-md-25px {
    padding: 25px !important;
  }

  body .px-md-12px {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  body .px-md-25px {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  body .px-md-18px {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }

  body .pb-md-25px {
    padding-bottom: 25px !important;
  }

  .ml-md-10 {
    margin-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .ant-col-24-xs {
    width: 100% !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .sm-hide {
    display: none !important;
  }
}

@media (min-width: 500px) {
  body .d-xs-none {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .ml-sm-6px {
    margin-left: 6px !important;
  }

  .mt-sm-6px {
    margin-top: 6px !important;
  }

  body .mt-sm-28px {
    margin-top: 28px !important;
  }

  body .p-sm-inherit {
    padding: inherit !important;
  }

  .border-radius-sm-20px {
    border-radius: 20px !important;
  }

  .border-sm-1px {
    border-width: 1px !important;
  }
}

@media (min-width: 400px) {
  body .d-xs-block {
    display: block !important;
  }

  .w-xs-auto {
    width: auto !important;
  }
}
